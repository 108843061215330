<template>
  <div class="grid response-wrap">
    <div class="col-12">
      <div class="card">
        <Toast />

        <!-- Table display all surveys -->
        <DataTable
          ref="dt"
          v-model:selection="selectedResponse"
          v-model:filters="filters"
          :value="respondentList"
          data-key="id"
          :paginator="true"
          :rows="10"
          filter-display="menu"
          paginator-template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rows-per-page-options="[5, 10, 25]"
          current-page-report-template="Showing {first} to {last} of {totalRecords} surveys"
          responsive-layout="scroll"
          :loading="loading"
        >
          <template #header>
            <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
              <h5 class="m-0">Manage Response</h5>
            </div>
          </template>
          <template #empty> No responses found. </template>
          <template #loading> Loading responses data. Please wait. </template>
          <Column selection-mode="multiple" header-style="width: 3rem" />
          <Column field="survey" header="Survey" :sortable="true">
            <template #body="{ data }">
              {{ data.survey.name }}
              <Button
                v-tooltip.bottom="'Edit Question'"
                icon="pi pi-pencil"
                class="p-button-rounded button-edit"
                @click="$router.push({ path: '/survey/' + data.survey.id })"
              />
            </template>
            <template #filter="{ filterModel }">
              <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Search by Survey Name" />
            </template>
          </Column>
          <Column field="answer" header="Answer">
            <template #body="slotProps">
              <div v-for="items in slotProps.data.answer" :key="items">
                {{ items[0] }}
              </div>
            </template>
          </Column>
          <Column field="profile" header="Profile">
            <template #body="slotProps">
              <div v-for="items in slotProps.data.profile" :key="items">
                {{ items.key + ":" + items.score }}
              </div>
            </template>
          </Column>
          <Column field="created_at" header="Response Time">
            <template #body="slotProps">
              {{ slotProps.data.created_at }}
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button v-tooltip.bottom="'View Response'" icon="pi pi-search" class="p-button-rounded button-edit" @click="viewSurveyForm(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import respondentService from "../../service/respondent";
import moment from "moment";

export default {
  data() {
    return {
      respondentList: null,
      loading: true,
      selectedResponse: null,
    };
  },

  mounted() {
    this.updateTable();
  },
  methods: {
    updateTable() {
      this.loading = true;
      respondentService.getRespondentList().then((result) => {
        this.respondentList = {};
        this.respondentList = result;
        this.respondentList.forEach((response) => {
          console.log(response);

          let answer = [];
          for (let ans in response.answer) {
            let x = [parseInt(ans) + 1 + ": " + response.answer[ans].answer];
            answer.push(x);
          }
          response.answer = answer;

          let profile = [];
          for (let pro in response.profile) {
            let obj = {};
            obj.key = pro;
            obj.score = response.profile[pro];
            profile.push(obj);
          }
          response.profile = profile;

          response.created_at = moment(response.created_at).format("MMMM Do YYYY, h:mm:ss a");
        });
        this.loading = false;
      });
    },
    viewSurveyForm(response) {
      this.$router.push({ path: "/response/" + response.id });
    },
  },
};
</script>

<style scoped lang="scss">
.response-wrap {
  ::v-deep(.p-datatable-table) {
    .p-datatable-tbody {
      tr {
        // td:nth-child(2) {
        //   display: flex;
        //   align-items: center;
        //   justify-content: flex-start;
        // }
        td {
          .button-edit {
            background: #a9a9a9;
            border-color: #a9a9a9;
            margin-left: 1rem;
          }
          .button-delete {
            background: #4c4c4c;
            border-color: #4c4c4c;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}
</style>
